// eslint-disable-next-line import/no-extraneous-dependencies
import Qs from 'qs';
import moment from 'moment';

/**
 * 判断是否微信环境
 * @returns {Boolean}
 */
export function isWeixin () {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
    return true;
  }
  return false;
}

/**
 * 判断终端系统类型
 * @returns {String}
 */
export function checkSystemType () {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // g
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) {
    // 这个是安卓操作系统
    return 'android';
  }
  if (isIOS) {
    // 这个是ios操作系统
    return 'ios';
  }
  return '未知';
}

/**
 * 防抖处理
 * @param {*} wait
 */
export function debounce (wait) {
  let timer = null;
  return function (target, name, descriptor) {
    const fn = descriptor.value;

    descriptor.value = function (...rest) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(this, ...rest);
      }, wait);
    };
  };
}

/**
 * 时间格式化
 * 数据返回为：2020-05-14 16:00:00
 * 页面展示：2020.05.06 16:00:00
 */
export function dateFmt (date) {
  if (!date) return '';
  return date.replace(/-/g, '/');
}
/**
 * 获取url参数
 */
export function getUrlParser () {
  return Qs.parse(window.location.search.substring(1));
}

export const getDevice = function () {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) {
    return 'android';
  }
  if (isiOS) {
    return 'ios';
  }
};

// 获取url query参数方法
export const getQueryStringArgs = function () {
  const qs = location.search.length > 0 ? location.search.substring(1) : '';
  const args = {};
  const arr = qs.split('&').map((kv) => kv.split('='));
  arr.forEach((item) => {
    args[item[0]] = item[1];
  });
  return args;
};

export const desensitizePhone = function (phone) {
  return phone?.replace(/^(.{3})(?:\d+)(.{4})$/, '$1****$2');
};

export const desensitizeName = function (userName) {
  if (userName && userName.length <= 2) {
    return userName && `${userName[0]}*`;
  }
  return userName && userName[0] + '*'.repeat(userName.length - 2) + userName[userName.length - 1];
};

export const getFileSize = function (base64String) {
  // 截取 base64 字符串中逗号后面的部分
  const base64WithoutHeader = base64String.split(',')[1];

  // 解码 Base64 字符串为二进制数据
  const byteCharacters = atob(base64WithoutHeader);

  // 计算字节长度
  const byteLength = byteCharacters.length;

  // 计算文件大小
  const fileSizeInBytes = byteLength;
  const fileSizeInKB = fileSizeInBytes / 1024;
  const fileSizeInMB = fileSizeInKB / 1024;

  return fileSizeInMB;
};

// base64图片转file的方法（base64图片, 设置生成file的文件名）
export const base64ToFile = function (base64, fileName = '测试') {
  // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
  const data = base64.split(',');
  // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
  const type = data[0].match(/:(.*?);/)[1];
  // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
  const suffix = type.split('/')[1];
  // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
  const bstr = window.atob(data[1]);
  // 获取解码结果字符串的长度
  let n = bstr.length;
  // 根据解码结果字符串的长度创建一个等长的整形数字数组
  // 但在创建时 所有元素初始值都为 0
  const u8arr = new Uint8Array(n);
  // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
  while (n--) {
    // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 利用构造函数创建File文件对象
  // new File(bits, name, options)
  const file = new File([u8arr], `${fileName}.${suffix}`, {
    type,
  });
  // 将File文件对象返回给方法的调用者
  return file;
};

export const computeAfterDay = function (givenDateStr) {
  const givenDate = moment(givenDateStr);
  // 获取当前日期的moment对象
  const now = moment(moment().format('YYYY-MM-DD'));
  // 比较当前日期是否超出了给定日期
  const isAfter = now.isAfter(givenDate);
  return isAfter;
};
